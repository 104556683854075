@import url('https://fonts.googleapis.com/css2?family=Poppins:ital@0;1&display=swap');

*, *::after, *::before{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body{
  max-width: 30rem;
  margin: 0 auto;
  margin-top: 2rem;
  padding: 2rem;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

@media screen and (max-width: 480px) {
  body{
    max-width: 20rem;
  }
}
h2{
  text-align: center;
  margin-bottom: 1rem;
}
nav{
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: .8rem;
}

.nav-tabs{
  text-decoration: none;
  color: black;
  padding: .5rem 2rem;
}

.active-tab{
  color: rgb(99, 224, 233);
}

input[type=text] {
  width: 100%;
  border: none;
  border-bottom: 2px solid rgb(99, 224, 233);
  margin-bottom: 1rem;
}

input[type=text]:focus{
  outline: none;
}

form > div{
  display: flex;
  align-items: center;
  justify-content: center;
}

.addbtn{
  padding: .3rem 2rem;
  border: none;
  background: rgb(99, 224, 233);
  border-radius: 5px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.info{
  margin: 1rem .5rem;
  text-align: center;
  font-size: small;
  font-style: italic;
}

footer{
  color: gray;
  text-align: center;
  margin-top: 1.5rem;
  font-size: small;
}

hr{
  margin-bottom: .5rem;
}

.todos-counter{
  text-align: center;
  margin-top: 2rem;
  font-style: italic;
}